import React from 'react';
import { BrowserRouter as Router, Route, Routes, useNavigate, Navigate } from 'react-router-dom';
import ClaimCard from './pages/ClaimCard';
import HomePage from './pages/HomePage.jsx';
import CardRedeemed from './components/CardRedeemed';
// import CollectionIncomplete from './pages/CollectionIncomplete';
// import CollectionComplete from './pages/CollectionComplete';
import Collection from './pages/Collection';
import ScrollToTop from './components/ScrollToTop';
import { AppProvider } from './AppContext';
import PrivateRoute from './components/PrivateRoute';

function App() {

  return (
    <AppProvider>
      <Router>
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<HomePage/>}/>
          <Route path="/wicked" element={<HomePage/>}/>

            {/* Private Routes for authenticated users */}
          <Route path="/wicked/claim-card" 
            element={
            <PrivateRoute>
              <ClaimCard/>
            </PrivateRoute>
            }/>

          <Route path="/wicked/collection" 
            element={
            <PrivateRoute>
              <Collection/>
            </PrivateRoute>
            }/>

          <Route path="*" element={<Navigate to="/wicked" />} />
        </Routes>
      </Router>
    </AppProvider>
  );
}

export default App;

const addScriptTag = (id, src, additionalProps) => {
  if (!document.getElementById(id)) {
    const scriptTags = document.getElementsByTagName('script')
    const lastScriptTag = scriptTags == null ? null : scriptTags[scriptTags.length - 1]
    const scriptTag = document.createElement('script')
    scriptTag.id = id
    scriptTag.src = src
    Object.keys(additionalProps ?? {}).forEach((key) => { scriptTag[key] = additionalProps[key] })
    if (lastScriptTag) {
      lastScriptTag.parentNode.insertBefore(scriptTag, lastScriptTag.nextSibling)
    } else {
      document.body.insertBefore(scriptTag, document.body.firstChild)
    }
  }
}

export default addScriptTag

import React from "react";
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import "./FloatingCards.css";
const cardImg = require('../assets/mysterycard.png');

const FloatingCards = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  // The card data (You can replace these with actual image paths)
  const cards = [
    { id: 1 },
    { id: 2 },
    { id: 3 },
    { id: 4 },
    { id: 5 },
    { id: 6 },
  ];

  if (!isSmallScreen) {
    return (
      <div className={`floating-card-container${isSmallScreen ? "-sm" : ""}`}>
        {cards.map((card, index) => (
          <div key={card.id}
            className={`floating-card${isSmallScreen ? "-sm" : ""} floating-card-${index + 1}${isSmallScreen ? "-sm" : ""}`}>
            <img src={cardImg} alt={`Card ${card.id}`} />
          </div>
        ))}
      </div>
    );
  }
  else {
    return (
      <div className={`floating-card-container${isSmallScreen ? "-sm" : ""} single`}>
        <div
          className={`floating-card${isSmallScreen ? "-sm" : ""} floating-card-single${isSmallScreen ? "-sm" : ""}`}>
          <img src={cardImg} />
        </div>
      </div>
    );
  }
};

export default FloatingCards;

import '../pages/Home.css';
import { Box, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import DoNotDisturbOnIcon from '@mui/icons-material/DoNotDisturbOn';

const universal_logo = require('../assets/logos.png');
const aptos_logo = require('../assets/aptos_logo.png');

const Footer = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box>
      <img src={universal_logo} className='universal-logo2' />
      <footer style={{
        padding: '20px 0',
        paddingTop: isSmallScreen ? '3px' : '20px',
        paddingBottom: isSmallScreen ? '80px': '20px',
        fontSize: isSmallScreen ? '8px' : '12px' }}>
        <a href="https://www.nbcuniversal.com/privacy/cookies#accordionheader2"
          style={isSmallScreen ? styles.smLink : styles.link} target="_blank">
          Ad Choices
        </a>
        <a href="https://www.nbcuniversal.com/privacy"
          style={isSmallScreen ? styles.smLink : styles.link} target="_blank">
          Privacy Policy
        </a>
        {/* <DoNotDisturbOnIcon style={styles.icon} /> */}
        <a href="https://www.nbcuniversal.com/privacy/california-consumer-privacy-act"
          style={isSmallScreen ? styles.smLink : styles.link} target="_blank">
          CA Notice
        </a>
        <a href="https://www.nbcuniversal.com/privacy/notrtoo"
          style={isSmallScreen ? styles.smLink : styles.link} target="_blank">
          Your Privacy Choices
        </a>
        <a href="https://www.nbcuniversal.com/terms"
          style={isSmallScreen ? styles.smLink : styles.link} target="_blank">
          Terms of Service
        </a>
      </footer>
    </Box>
  );
};

const styles = {
  footer: {
    position: 'fixed', // Stick to the bottom
    bottom: 0,
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#f1f1f1',
    padding: '10px 0',
  },
  link: {
    margin: '0 20px',
    textDecoration: 'none',
    color: '#fff',
    textAlign: 'center'
  },
  smLink: {
    margin: '0 5px',
    textDecoration: 'none',
    color: '#fff',
    textAlign: 'center'
  },
  icon: {
    position: 'absolute',
    color: '#fff', // Color of the icon
    left: '48rem',
    width: '1%',
  },
};

export default Footer;
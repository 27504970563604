import React, { useEffect, useState, useRef } from 'react';
import { motion, useAnimation, useInView } from 'framer-motion';
import { Box, Container, Grid2, useMediaQuery } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import '../pages/Home.css'
import { Padding } from '@mui/icons-material';
import CardPack from './CardPack';

const ScrollAnimation: React.FC = () => {
  const navigate = useNavigate();

  const poster = require('../assets/wicked_banner.jpg');

  const phone = require('../assets/phone.png');
  const pack = require('../assets/starter_pack.png');
  const wizard = require('../assets/wizard.png');
  const elphaba = require('../assets/elphaba.png');
  const glinda = require('../assets/glinda.png');
  const madame = require('../assets/madame.png');
  const fiyero = require('../assets/fiyero.png');
  const nessa = require('../assets/nessarose.png');
  const wicked_logo = require('../assets/wicked_logo_small.png');
  const regal_logo = require('../assets/2018RegalCrownClubCard_RGB.png');
  const cards_static = require('../assets/cards_static_2.png');
  const isSmallScreen = useMediaQuery('(max-width:600px)');
  const isMediumScreen = useMediaQuery('(max-width:960px)');
  const [isHovered, setIsHovered] = useState(false);

  return (
    <Container style={{ padding: 0 }}>
      <Box className="banner-container">
        <img src={poster} className='wicked-banner' alt='Wicked Banner'
          style={{
            width: '100%',
            borderRadius: isSmallScreen ? '10px' : '20px',
            transform: isSmallScreen ? 'translateX(0%)' : 'none',
            height: 'auto', // Maintain aspect ratio
          }} />
      </Box>

      <div style={{ width: '100%' }}>
        <img src={regal_logo} width={isSmallScreen ? 140 : 200} style={{
          display: 'block',
          margin: isSmallScreen ? '25px auto 15px auto' : '45px auto 15px auto',
        }} />
        <p style={{
          color: '#f36a23',
          fontSize: 15,
          textAlign: 'center',
          textTransform: 'uppercase',
          fontWeight: 600,
          letterSpacing: '1px',
        }}>Regal Crown Club Member Exclusive</p>
        <h2 style={{
          color: '#fff',
          // fontFamily: 'Century Gothic Regular', 
          fontWeight: 600,
          // fontSize: '3rem', 
          // lineHeight: '64.704px',
          textAlign: 'center',
          // textTransform: 'capitalize',
          lineHeight: '48px',
          fontSize: isSmallScreen ? '34px' : '38px',
          maxWidth: 780,
          display: 'block',
          margin: '0 auto',
          marginTop: 10,
          // marginLeft: '20%',
          // border: '1px solid #ddd',
        }}>
          Watch Wicked at a Regal Theater Near You to Receive a Free Digital Reward!
        </h2>

      </div>
      <div style={{ margin: '15px auto', display: 'block' }}>
        <a className="link-btn" href='https://www.regmovies.com/movies/wicked-ho00016009' target='_blank'>
          Buy Tickets
        </a>
      </div>


      {/* testing */}
      {/* <Grid2 container sx={{
        fontSize: { xs: '0.8em', sm: '1.2rem', md: '1.4rem', lg: '1.5rem', xl: '2rem' },
        margin: '10px auto 80px auto'
      }}>
        <div style={{ width: '100%' }}>
          <img src={regal_logo} width={140} style={{
            display: 'block', margin: '45px auto 15px auto'
          }} />
          <p style={{
            color: '#f36a23',
            fontSize: 15,
            textAlign: 'center',
            textTransform: 'uppercase',
            fontWeight: 600,
            letterSpacing: '1px',
          }}>Regal Crown Club Member Exclusive</p>
          <h2 style={{
            color: '#fff',
            // fontFamily: 'Century Gothic Regular', 
            fontWeight: 600,
            // fontSize: '3rem', 
            // lineHeight: '64.704px',
            textAlign: 'center',
            // textTransform: 'capitalize',
            lineHeight: '48px',
            fontSize: '38px',
            width: '67%',
            display: 'block',
            margin: '0 auto',
            marginTop: 10,
            // marginLeft: '20%',
            // border: '1px solid #ddd',
          }}>
            Watch Wicked at a Regal Theater Near You to Receive a Free Digital Reward!
          </h2>

        </div>
        <div style={{ margin: '15px auto', display: 'block' }}>
          <a className="link-btn" href='https://www.regmovies.com/movies/wicked-ho00016009' target='_blank'>
            Buy Tickets
          </a>
        </div>
      </Grid2>
      <Grid2
        className='phone-container'
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        onTouchStart={() => setIsHovered(true)}
      // ref={ref}
      >

        <img src={pack} alt='Card Collection Packaging' style={{
          height: '45%',
          marginTop: '22rem',
          justifyContent: 'center',
          // border: '1px solid #ddd',
        }} />

        <motion.img
          src={wizard}
          alt='Wizard Card'
          className='wizard-card'
          animate={{
            x: isHovered ? '30%' : '0',
            y: isHovered ? '-110%' : '0',
            rotate: isHovered ? 15 : 0,
            transition: { duration: 0.6, ease: 'easeOut' }
          }}
        />

        <motion.img
          src={elphaba}
          alt='Elphaba Card'
          className='elphaba-card'
          animate={{
            x: isHovered ? '-15%' : '0',
            y: isHovered ? '-50%' : '0',
            rotate: isHovered ? -15 : 0,
            transition: { duration: 0.6, ease: 'easeOut' }
          }}
        />

        <motion.img
          src={glinda}
          alt='Glinda Card'
          className='glinda-card'
          animate={{
            x: isHovered ? '30%' : '0',
            y: isHovered ? '-55%' : '0',
            rotate: isHovered ? 25 : 0,
            transition: { duration: 0.6, ease: 'easeOut' }
          }}
        />

        <motion.img
          src={madame}
          alt='Madame Morrible Card'
          className='madame-card'
          animate={{
            x: isHovered ? '35%' : '0',
            y: isHovered ? '-80%' : '0',
            rotate: isHovered ? 18 : 0,
            transition: { duration: 0.6, ease: 'easeOut' }
          }}
        />

        <motion.img
          src={fiyero}
          alt='Fiyero Card'
          className='fiyero-card'
          animate={{
            x: isHovered ? '-15%' : '0',
            y: isHovered ? '-75%' : '0',
            rotate: isHovered ? -15 : 0,
            transition: { duration: 0.6, ease: 'easeOut' }
          }}
        />
        <motion.img
          src={nessa}
          alt='Nessarose Card'
          className='nessarose-card'
          animate={{
            x: isHovered ? '-15%' : '0',
            y: isHovered ? '-100%' : '0',
            rotate: isHovered ? -17 : 0,
            transition: { duration: 0.6, ease: 'easeOut' }
          }}
        />
      </Grid2> */}


      <CardPack/>
    </Container>
  );
};

export default ScrollAnimation;
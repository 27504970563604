import React, { useEffect, useRef, useState } from "react";
import { Box, Container, Grid2, useMediaQuery } from '@mui/material';
import './CardPack.css';

const pack = require('../assets/starter_pack.png');
const card = require('../assets/mysterycard.png');

const CardPack = () => {
  const packRef = useRef(null); // Reference to the card pack container
  const [isRevealed, setIsRevealed] = useState(false);
  const isSmallScreen = useMediaQuery('(max-width:600px)');
  const isMediumScreen = useMediaQuery('(max-width:960px)');

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setIsRevealed(true); // Set the revealed state to true
            observer.unobserve(entry.target); // Stop observing once animation triggers
          }
        });
      },
      { threshold: 0.5 } // Trigger when 50% of the pack is visible
    );

    if (packRef.current) {
      observer.observe(packRef.current); // Start observing the card pack
    }

    return () => {
      if (packRef.current) {
        observer.unobserve(packRef.current); // Cleanup observer on component unmount
      }
    };
  }, []);

  return (
    <div
      className={`pack-container${isSmallScreen ? "-sm" : ""} ${isRevealed ? "reveal" : ""}`}
      ref={packRef}
    >
      {/* Replace the card pack div with an img element */}
      <img
        src={pack}
        alt="Card Pack"
        className="card-pack-img"
      />
      <img className={`card ${isSmallScreen ? "card-sm" : ""}`} src={card}/>
      <img className={`card ${isSmallScreen ? "card-sm" : ""}`} src={card}/>
      <img className={`card ${isSmallScreen ? "card-sm" : ""}`} src={card}/>
      <img className={`card ${isSmallScreen ? "card-sm" : ""}`} src={card}/>
      <img className={`card ${isSmallScreen ? "card-sm" : ""}`} src={card}/>
      <img className={`card ${isSmallScreen ? "card-sm" : ""}`} src={card}/>
    </div>
  );
};

export default CardPack;

import React from "react";
import { Box, Container, Grid2, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import '../pages/Home.css';
import QuestionAnswer from "./QuestionAnswer";

const questions = [
  { question: "What is an NFT?", answer: "A NFT is a “Non-Fungible-Token”, which is a digital asset where your ownership of the asset is recorded on the blockchain and your asset is held in a digital wallet." },
  { question: "How do I see my NFTs?", answer: "When you collect your 1st NFT, you will be prompted to create a digital wallet to contain your NFTs." },
  { question: "Can I resell my WICKED DIGITAL CARD NFT on an open marketplace?", answer: "Not at this time." },
  { question: "What blockchain is this NFT minted on?", answer: "Our transactions are done on the Aptos blockchain; Aptos blockchain is one of the most energy efficient blockchains." },
  { question: "I have my own web3 wallet. How do I see my NFTs there?", answer: "If you have a 3rd party wallet application like Petra and would like to bring your digital card NFTs there, please contact support@moviebonus.com." },
  { question: "Who can I contact for technical issues?", answer: "Please contact support@moviebonus.com." },
];

const FAQ: React.FC = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box>
      <div className={isSmallScreen ? "faq-section faq-section-sm" : "faq-section"}>
        <p style={{
          position: 'relative',
          // alignSelf: 'center',
          fontSize: 36,
          color: '#fff',
          fontWeight: 600,
          marginTop: 15
          // border: '1px solid #ddd',
        }}>FAQ</p>
        {questions.map((qa, i) => {
          return <QuestionAnswer key={i} question={questions[i]['question']} answer={questions[i]['answer']} />
        })}
      </div>
    </Box>
  );
};

export default FAQ;
import { useEffect, useState } from 'react';
import { Grid2, Box, CircularProgress, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import './CollectionIncomplete.css';
import Footer from '../components/Footer.jsx';
import { API_BASE_URL, aptos_network } from '../constants';
import { useAppContext } from '../AppContext';
import CloseIcon from '@mui/icons-material/Close';

const NETWORK = aptos_network;

const wicked_logo = require('../assets/wicked_logo_small.png');
const elphaba = require('../assets/elphaba_small.png');
const glinda = require('../assets/glinda_small.png');
const fiyero = require('../assets/fiyero_small.png');
const madame = require('../assets/madame_small.png');
const nessarose = require('../assets/nessarose_small.png');
const wizard = require('../assets/wizard_small.png');
const elphaba_question = require('../assets/elphaba_question_small.png');
const glinda_question = require('../assets/glinda_question_small.png');

const getOptimizedCard = (card) => {
  switch (card.name.toLowerCase()) {
    case 'elphaba':
      return elphaba;
    case 'glinda':
      return glinda;
    case 'fiyero':
      return fiyero;
    case 'madame':
    case 'madame morrible':
      return madame;
    case 'nessarose':
      return nessarose;
    case 'wizard':
    case 'the wizard':
      return wizard;
  }
};

const getOptimizedQuestionCard = (card) => {
  switch (card.name.toLowerCase()) {
    case 'elphaba':
      return elphaba_question;
    case 'glinda':
      return glinda_question;
  }
};

// Modal component that appears when card is selected
const CharacterCardModal = ({ onClose, card, walletAddr }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <div style={isSmallScreen ? styles.overlaySm : styles.overlay}>
      <Box sx={{ width: '100%', padding: '0px' }}>
        <Grid2 container spacing={2} alignItems={'center'} justifyContent={'center'}>
          <Grid2 item xs={12} display={'flex'} justifyContent={'center'} >
            <img src={getOptimizedCard(card)} style={{
              // position: 'absolute',
              // left: window.innerWidth <= 768 ? '10%' : '30%',
              // top: window.innerWidth <= 768 ? '14%' : '32%',
              // width: window.innerWidth <= 768 ? '20rem' : '19rem', 
              width: '90%',
              maxWidth: '400px',
              objectFit: 'cover',
              marginTop: '10px',
            }} />
          </Grid2>
          <Grid2 item xs={12} display="flex" flexDirection="column" justifyContent="center">
            <h2 style={{
              fontSize: { xs: 34, sm: 40 },
              color: '#fff',
              textAlign: isSmallScreen ? 'center' : 'left',
              margin: '5px 0 0 0'
            }}>
              {card.name}
            </h2>
            <p style={{
              fontSize: { xs: 14, sm: 24 },
              marginTop: { xs: '0px', sm: '-5px' },
              width: '22rem',
              color: '#fff',
              textAlign: isSmallScreen ? 'center' : 'left',
              alignItems: { xs: 'center', sm: 'flex-start' },
              marginBottom: 0
            }}>
              {card.description}
            </p>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: { xs: 'center', sm: 'flex-start' },
                alignItems: { xs: 'center', sm: 'flex-start' },
                marginTop: 'auto', // Pushes the button to the bottom
                width: '100%',
                paddingBottom: '20px'
              }}
            >
              <button
                onClick={
                  () => window.location = `https://explorer.aptoslabs.com/account/${walletAddr}/tokens?network=${NETWORK}`
                }
                disabled={true}
                sx={{
                  paddingTop: '12px',
                  paddingBottom: '5.6%',
                  // marginTop: window.innerWidth <= 768 ? '20px' : '-5px',
                  marginTop: '20px',
                  fontSize: 20,
                  // alignSelf: window.innerWidth <= 768 ? 'center': 'flex-start',
                  alignSelf: { xs: 'center', sm: 'flex-start' },
                  justifyContent: 'center',
                }}>View on Aptos <i style={{fontWeight: 100, fontSize: '9px', display: 'block'}}>(Coming Soon)</i></button>
            </Box>
          </Grid2>
          <button onClick={onClose} style={styles.closeButton}><CloseIcon/></button>
          {/* </Grid2> */}
        </Grid2>
      </Box>
    </div>
  );
};


const Collection = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const navigate = useNavigate();

  const [allCollections, setAllCollections] = useState([])
  const [showModal, setShowModal] = useState(false);
  const [selectedCard, setSelectedCard] = useState({});
  const [redeemedCards, setRedeemedCards] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [walletAddr, setWalletAddr] = useState('');
  const { userId } = useAppContext();

  const handleCloseModal = () => {
    setSelectedCard({});
    setShowModal(false);
  };

  useEffect(() => {
    console.log(userId);
    setIsLoading(true);
    const getRedeemedCodes = async () => {
      if (!userId) { return; }
      const url = `${API_BASE_URL}/codes/redeemedBy/${userId}`;
      console.log("userId", userId);
      const result = await fetch(url, { headers: { 'accept': 'application/json' } });
      const json = await result.json();
      console.log("get redeemed codes >>>", json);
      const redeemed = {};
      json.map((card) => {
        card.mint_id.split(",").map((c) => {
          const collectionId = c.split(":")[0];
          if (!redeemed[collectionId]) {
            redeemed[collectionId] = 0;
          }
          redeemed[collectionId] += 1;
        });
      });
      console.log(redeemed);
      setRedeemedCards(redeemed);
      setIsLoading(false);
    };

    const fetchCollectionImage = async () => {
      const getCardLink = `${API_BASE_URL}/collection`;
      const result = await fetch(getCardLink, { headers: { 'accept': 'application/json' } });
      const json = await result.json();
      console.log("get all collections >>>", json);
      setAllCollections(json);
    };

    const getWalletAddr = async () => {
      if (!userId) { return; }
      const getUser = `${API_BASE_URL}/users/${userId}/link`;
      const result = await fetch(getUser, { headers: { 'accept': 'application/json' } });
      if (result.ok) {
        const json = await result.json();
        console.log("user >>>", json);
        setWalletAddr(json.wallet_addr);
      }
      else {
        console.log("no user link found.");
      }
    };


    fetchCollectionImage();
    getRedeemedCodes();
    getWalletAddr();

  }, [userId]);

  const logout = async () => {
    console.log("Logging out...");
    if (window.nbc && window.identity) {
      // setTimeout(
      //   () => {
      //     console.log("navigating to homepage");
      //     navigate('/wicked/');
      //   },
      //   3000
      // );
      window.nbc.identity.unauthenticate();
    }
  }

  return (
    <div>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'flex-start',
          flexDirection: 'row',
          justifyContent: 'space-between',
          p: 0,
          m: 0,
        }}
      >
        <img
          onClick={() => navigate('/wicked/')}
          src={wicked_logo}
          alt="Logo"
          className="logo"
          width={160}
        />
        {/* <button onClick={logout} className='redeemed-logout-btn'style={{
          marginRight: isSmallScreen ? '10px' : '20px'
        }}>
          Log Out
        </button> */}
        <button onClick={() => navigate('/wicked/')} className='redeemed-logout-btn' style={{
          marginRight: isSmallScreen ? '10px' : '20px'
        }}>
          Back Home
        </button>
      </Box>

      <div style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        display: showModal ? 'none' : 'block',
        // opacity: showModal ? 0 : 1,
      }}>
        <h1
          style={{
            color: '#fff',
            textAlign: 'center',
            fontWeight: 300,
            fontSize: 56,
            marginTop: '10px',
            marginBottom: '10px',
          }}
        >
          Your Wicked Collection
        </h1>

        {isLoading ? (
          <CircularProgress color="success" size={50} style={{
            margin: '40px auto 30px auto',
            display: 'block',
          }} />
        ) : (
          <>
            <Grid2 container spacing={2} sx={{ padding: 0, display: 'flex', justifyContent: 'center', flexWrap: 'wrap', gap: '20px', padding: '20px', maxWidth: '1200px', margin: '0 auto' }}>
              {allCollections
                .filter((collection) => {
                  const name = collection.name.toLowerCase();
                  return (name == "elphaba" || name == "glinda");
                })
                .map((collection, i) => {
                  const inCollection = redeemedCards[collection.collection_id] >= 1;
                  if (inCollection) {
                    return (
                      <Grid2 key={i} style={styles.card}>
                        <div className="card-container"
                          style={{ cursor: 'pointer', width: '20rem', textAlign: 'right' }}>
                          <img src={getOptimizedCard(collection)}
                            style={{ cursor: 'pointer', width: '100%', textAlign: 'right' }}
                            onClick={() => {
                              setSelectedCard(collection);
                              setShowModal(true);
                            }} />
                        </div>
                        <div style={styles.count}>x{redeemedCards[collection.collection_id]}</div>
                      </Grid2>
                    );
                  }
                  else {
                    return (
                      <Grid2 key={i} style={styles.card}>
                        <img src={getOptimizedQuestionCard(collection)}
                          style={{ cursor: 'pointer', width: '20rem', textAlign: 'right' }} />
                      </Grid2>
                    );
                  }
                }
                )}
            </Grid2>

            <Grid2 container spacing={2} sx={{ padding: 0, display: 'flex', justifyContent: 'center', flexWrap: 'wrap', gap: '20px', padding: '20px', maxWidth: '1200px', margin: '0 auto' }}>
              {allCollections
                .filter((collection) => {
                  const name = collection.name.toLowerCase();
                  if (name !== "elphaba" && name !== "glinda") {
                    return redeemedCards[collection.collection_id] >= 1;
                  }
                  return false;
                })
                .map((collection, i) => {
                  return (
                    <Grid2 key={i} style={styles.card}>
                      <div className="card-container"
                        style={{ cursor: 'pointer', width: '16rem', textAlign: 'right' }}>
                        <img src={getOptimizedCard(collection)}
                          style={{ width: '100%' }}
                          onClick={() => {
                            setSelectedCard(collection);
                            setShowModal(true);
                          }} />
                      </div>
                      <div style={styles.count}>x{redeemedCards[collection.collection_id]}</div>
                    </Grid2>
                  );
                }
                )}
            </Grid2>
          </>
        )}

        <Grid2 container sx={{
          fontSize: { xs: '0.9rem', sm: '1.2rem', md: '1.4rem', lg: '1.5rem', xl: '1.6rem' },
          maxWidth: { xs: '100%', sm: '30rem', md: '65%', lg: '45rem', xl: '100%' },
          lineHeight: { xs: '35px', md: '45px', xl: '64.704px' },
          margin: '0 auto'
        }}>
          <h2
            style={{
              color: '#fff',
              fontWeight: 300,
              textAlign: 'center',
              width: '90%',
              display: 'block',
              margin: '0px auto 10px',
              textTransform: 'uppercase',
              lineHeight: '37px',
              // letterSpacing: '2px',
              fontSize: '26px'
            }}
          >
            Watch the movie again and be the first to unlock surprises in an upcoming mobile game!
          </h2>
        </Grid2>
        <div style={{ margin: '15px auto 60px auto', display: 'block' }}>
          <a className="link-btn" href='https://www.regmovies.com/movies/wicked-ho00016009' target='_blank'>
            Buy Tickets
          </a>
        </div>
        <Footer />
      </div>

      {showModal && <CharacterCardModal onClose={handleCloseModal} card={selectedCard} walletAddr={walletAddr} />}

    </div>
  );
};

// Basic styles for modal and overlay
const styles = {
  overlay: {
    position: 'fixed',
    overflow: 'auto',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0)', // Dim the entire background
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1000, // Ensure the modal is on top
  },
  overlaySm: {
    position: 'fixed',
    overflow: 'auto',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0)', // Dim the entire background
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'top',
    zIndex: 1000, // Ensure the modal is on top
  },
  modal: {
    backgroundColor: '#000',
    padding: '20px',
    borderRadius: '8px',
    boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
    display: 'flex',
    // flexDirection: 'row',
    flexDirection: window.innerWidth <= 768 ? 'column' : 'row', // Stack vertically on small screens
    justifyContent: 'space-between',
    alignItems: 'center',
    marginLeft: '10rem',
  },
  closeButton: {
    position: 'fixed',
    top: '5px',
    right: '10px',
    padding: '23px',
    backgroundColor: 'black',
    border: '2px solid #14a905',
    opacity: 0.7,
    borderRadius: '300px',
    width: '26px',
    height: '26px',
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  card: {
    marginBottom: 30,

  },
  count: {
    color: '#ffffff',
    textAlign: 'right'
  }
}

export default Collection;
import React, { useState, useRef } from 'react';
import '../pages/ClaimCard.css';
import { useAppContext } from '../AppContext';
import { Link } from 'react-router-dom';
import { CircularProgress } from '@mui/material';


interface CodeInputProps {
  length: number;
  onComplete: (code: string) => void;
  onSubmit: (code: string) => void;
}

const CodeInput: React.FC<CodeInputProps> = ({ length, onComplete, onSubmit }) => {
  const { pasteCodeResult, setPasteCodeResult } = useAppContext();

  const [values, setValues] = useState(new Array(6).fill(''));
  const inputRefs = useRef<(HTMLInputElement | null)[]>([]);

  const [codeEntered, setCodeEntered] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (val: string, index: number) => {
    if (!/^[a-zA-Z0-9]*$/.test(val)) return; // allow only alphanumeric characters
    const newValues = [...values];
    newValues[index] = val;
    setValues(newValues);

    // automatically move to next input if valid input is detected
    if (val && index < length - 1) {
      const nextInput = document.getElementById(`code-input-${index + 1}`);
      nextInput?.focus();
    }

    // trigger the onComplete callback when all inputs are filled
    if (newValues.every((char) => char !== '')) {
      onComplete(newValues.join(''));
      setCodeEntered(true);
      // onSubmit(newValues.join(''));
    }
  };

  const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
    e.preventDefault();
    const pastedData = e.clipboardData.getData('text');
    setPasteCodeResult(pastedData);

    // Split pasted data into individual characters and populate the inputs
    if (pastedData.length === 6) {
      const newValues = pastedData.split('').slice(0, 6);
      setValues(newValues);
      console.log(newValues);
      console.log(values);

      // Move focus to the last field
      inputRefs.current[5]?.focus();

      // trigger the onComplete callback when all inputs are filled
      if (newValues.every((char) => char !== '')) {
        onComplete(newValues.join(''));
        setCodeEntered(true);
        // onSubmit(newValues.join(''));
      }
    }
  };

  const handleSubmit = async () => {
    const newValues = [...values];
    setIsLoading(true);
    await onSubmit(newValues.join(''));
    setIsLoading(false);
    console.log('new values', newValues);
  };

  return (
    <div>
      <div style={{ display: 'flex', gap: '10px' }}>
        {values.map((val, index) => (
          <input
            key={index}
            id={`code-input-${index}`}
            type="text"
            maxLength={1}
            value={val}
            onPaste={handlePaste}
            onChange={(e) => handleChange(e.target.value.toUpperCase(), index)}
            style={{
              width: '40px',
              fontSize: '20px',
              textAlign: 'center',
              color: '#fff',
            }}
          />
        ))}
      </div>
      <div>
        <p style={{
          color: '#cecece',
          fontWeight: 300,
          fontSize: '11px',
          textAlign: 'center',
          display: 'block',
          margin: '0 auto',
          // maxWidth: '300px',
          // lineBreak: 'anywhere',
          marginTop: 20
        }}>
          By clicking 'Claim' you are creating a public digital wallet that can be used to access your digital collectible on the Aptos blockchain network (view the <a href="https://aptoslabs.com/privacy" style={{color: '#218838'}} target="_blank">Aptos Privacy Policy</a>). Once your information is on the blockchain as a result of redeeming a digital collectible, it will be publicly accessible. NBCUniversal will not have the ability to control or monitor its use. More information can be found on our <Link to='/wicked/' state= {{ scrollToTarget: true }}>FAQ</Link> page.
        </p>
        <button
          className='claim-button'
          disabled={isLoading || length < 6 || !codeEntered}
          style={{
            opacity: length >= 6 ? 1 : 0.2,
            cursor: length >= 6 ? 'pointer' : 'not-allowed',
            width: '200px'
          }}
          onClick={handleSubmit}
        >
          {isLoading ? <CircularProgress color="success" size={25} style={{ margin: '0 auto', display: 'block'}}/> : "Claim"}
        </button>
      </div>
    </div>
  );
};

export default CodeInput;
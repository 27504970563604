import React, {
  useEffect,
  useRef,
  useState
} from 'react';
import { Box, Grid2, Container, useMediaQuery, CircularProgress, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import ScrollAnimation from '../components/ScrollAnimation';
import Footer from '../components/Footer.jsx';
import FAQ from '../components/FAQ';
import { useNavigate } from 'react-router-dom';
import './Home.css';
import addScriptTag from '../documentHelper';
import getSDKScript from '../scriptHelper';
import { SAMPLE_VERSION } from '../version';
import { useAppContext } from '../AppContext';
import { env } from '../constants';
import LocalActivityOutlinedIcon from '@mui/icons-material/LocalActivityOutlined';
import { useLocation } from 'react-router-dom';

const poster = require('../assets/wicked_banner.jpg');
const universal_logo = require('../assets/universal.png');
const wicked_logo = require('../assets/wicked_logo_small.png');
const aptos_logo = require('../assets/aptos_logo.png');

const EmbedStyle = Object.freeze({
  NONE: { key: 'none', name: 'None' },
  PAGE: { key: 'page', name: 'Page' },
  PANEL: { key: 'panel', name: 'Panel' },
});

const useRenderedRef = (value) => {
  const ref = useRef(value)
  const [, forceRender] = useState(value)

  function updateState(newState) {
    ref.current = newState
    forceRender(newState)
  }

  return [ref, updateState]
};

const HomePage = () => {
  const navigate = useNavigate();

  const [interfaceReady, setInterfaceReady] = useState(false)
  const [configurationKey, setConfigurationKey] = useState(null)
  const [configurationLocation, setConfigurationLocation] = useState(null)
  const [environment, setEnvironment] = useState(null)
  const [language, setLanguage] = useState(null)
  const [useBaseSdkLocation, setUseBaseSdkLocation] = useState(true)
  const [version, setVersion] = useState(null)
  const [defaultPage, setDefaultPage] = useState(null)
  const [crossDomain, setCrossDomain] = useState(false)
  const [redirectUrl, setRedirectUrl] = useState('')
  const [brandData, setBrandData] = useState('')
  const [referringPage, setReferringPage] = useState(null)
  const [registrationReferrer, setRegistrationReferrer] = useState(null)
  const [authenticatedUser, setAuthenticatedUser] = useState(null)
  const [isAuthenticated, setIsAuthenticated] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const sdkLocationRef = useRef(null)
  const autoLaunchRef = useRef(false)
  const embedContainerRef = useRef(null)
  const [embedStyleRef, setEmbedStyle] = useRenderedRef(null)
  const [embeddingRef, setEmbedding] = useRenderedRef(false)


  const { userId, setUserId } = useAppContext();

  const pageEmbedActive = () => embedStyleRef.current === EmbedStyle.PAGE.key &&
    embeddingRef.current

  const removeAllEmbedContainerChildren = () => {
    const { current: parent } = embedContainerRef
    if (parent) {
      while (parent.firstChild) {
        parent.removeChild(parent.firstChild)
      }
    }
  };

  const launchSdk = () => {
    let redirectUrl;
    switch(env) {
      case 'dev':
        redirectUrl = 'https://localhost:3000/';
        break;
      case 'stage':
        redirectUrl = 'https://local.moviebonus.com:3000/';
        break;
      case 'prod':
        redirectUrl = 'https://moviebonus.com/wicked/';
        break;
    }
    window.nbc.identity.launch({
      referringPage,
      defaultPage,
      brandData,
      redirectUrl: redirectUrl
    })
  };

  const initialize = () => {
    window.nbc.identity.customEventAttributes = { custom_app_data: 'web-Sample' }
    const urlParams = new URLSearchParams(window.location.search)

    const key = urlParams.get('key') ?? 'nbc'
    setConfigurationKey(key)

    const configurationLocationParam = urlParams.get('configLocation') ?? ''
    setConfigurationLocation(configurationLocationParam)

    // const env = urlParams.get('env') ?? 'dev'
    // setEnvironment(env)

    const languageParam = urlParams.get('language') ?? 'en'
    setLanguage(languageParam)

    const useBaseSdkLocationParam = urlParams.has('useBaseSdkLocation')
      ? urlParams.get('useBaseSdkLocation') === 'true'
      : process.env.REACT_APP_USE_BASE_SDK_LOCATION === 'true'
    setUseBaseSdkLocation(useBaseSdkLocationParam)

    const versionParam = urlParams.get('version') ?? SAMPLE_VERSION.charAt(0)
    setVersion(versionParam)

    const crossDomainParam = urlParams.get('crossDomain') === 'true'
    setCrossDomain(crossDomainParam)

    const redirectUrlParam = urlParams.get('redirectUrl')
    if (redirectUrlParam) {
      localStorage.setItem('nbcRedirectUrl', redirectUrlParam)
      setRedirectUrl(redirectUrlParam)
    }

    const brandDataParam = urlParams.get('brandData')
    setBrandData(brandDataParam)

    const debug = urlParams.has('debug') ? urlParams.get('debug') === 'true' : true

    setReferringPage(urlParams.get('referringPage'))
    setRegistrationReferrer(urlParams.get('registrationReferrer'))
    console.log(env);
    switch(env) {
      case 'dev':
        window.nbc.identity.initialize('config', {
          configLocationHost: 'https://wicked-gwp.s3.us-east-2.amazonaws.com',
          debug,
          env: 'dev',
          language: languageParam,
          useBaseSdkLocation: true,
          version: '2',
          crossDomain: true,
        });
        break;
      case 'stage':
        window.nbc.identity.initialize('wickedweb', {
          configLocationHost: null,
          debug,
          env: 'stage',
          language: languageParam,
          useBaseSdkLocation: true,
          version: '2',
          crossDomain: true,
        });
        break;
      case 'prod':
        window.nbc.identity.initialize('wickedweb', {
          configLocationHost: null,
          debug,
          env: 'production',
          language: languageParam,
          useBaseSdkLocation: true,
          version: '2',
          crossDomain: true,
        });    
        break;
    }

    setDefaultPage(urlParams.get('defaultPage'))
    setEmbedStyle(urlParams.get('embed') ?? EmbedStyle.PANEL.key)

    autoLaunchRef.current = urlParams.get('autoLaunch') === 'true'
    if (!autoLaunchRef.current) setInterfaceReady(true)
  };

  useEffect(() => {
    const handleSdkStatusChange = (status) => {
      console.log('sdk status changed: ', status)
      switch (status) {
        case 'initialized': {
          const storedOverridesJsonString = localStorage.getItem('launch_darkly_overrides')
          let overrides = {}
          if (storedOverridesJsonString) {
            overrides = JSON.parse(storedOverridesJsonString)
            window.nbc.identity.overrideLaunchDarklyConfigurations(overrides)
          }
          if (autoLaunchRef.current) launchSdk()
        }
        default:
        // do nothing
      }
    };

    const handleAuthenticationStateChange = (state) => {
      console.log('Authentication state changed', state);
      if (state === 'authenticated') {
        prettyNameForStatus(window.nbc.identity.signUpCompletionStatus())
        prettyNameForStatus(window.nbc.identity.completeProfileCompletionStatus())
        setIsAuthenticated(true)
      } else {
        setIsAuthenticated(false)
      }
      setIsLoading(false);
    };

    const handleUserChange = (user) => {
      console.log('User changed. Here\'s new user: ', user);
      setAuthenticatedUser(user);
      if (user) {
        setUserId(user._id);
      }
    };

    const handleWindowStateChange = (windowState) => {
      console.log('Window state changed to: ', windowState)
      if (windowState === 'opened' && autoLaunchRef.current) {
        setInterfaceReady(true)
      }
      else if (windowState === 'closed' && pageEmbedActive()) {
        setEmbedding(false)
        removeAllEmbedContainerChildren()
      }
    };

    const onSdkLoaded = () => {
      console.log('LOAD SDK');
      initialize()
      window.nbc.identity.on('status', handleSdkStatusChange);
      window.nbc.identity.on('authenticationState', handleAuthenticationStateChange);
      window.nbc.identity.on('user', handleUserChange);
      window.nbc.identity.on('windowState', handleWindowStateChange);
      setIsLoading(true);
    }

    const loadIdentitySdk = () => {
      setIsLoading(true);
      console.log(window.nbc);
      if (!window.nbc?.identity) {
        sdkLocationRef.current = getSDKScript()
        console.log(sdkLocationRef.current);
        addScriptTag(
          'nbc-identity-sdk-js',
          sdkLocationRef.current,
          { async: false, defer: false, onload: onSdkLoaded },
        )
      }
    };
    loadIdentitySdk();
  }, []);

  const prettyNameForStatus = (status) => {
    switch (status) {
      case 'complete':
        return 'Complete'
      case 'incomplete':
        return 'Incomplete'
      case 'notStarted':
        return 'Not Started'
      default:
        return `Unknown status ${status}`
    }
  };

  const sendAttribute = (key, val) => {
    console.log('Sending Attriute =>', `${key}:${val}`)
    window.nbc.identity.analyticsEventLogger.setMParticleUserAttributes({ key, val })
  };

  const login = () => {
    // sendAttribute('wallet', '0xSherryWong')
    // redirectUrl: 'https://local.moviebonus.com:3000/',
    // redirectUrl: 'https://localhost:3000/'
    // redirectUrl: 'https://d2nbg7m24ttiy8.cloudfront.net/'
    // redirectUrl: 'https://stage.moviebonus.com/'
    // redirectUrl: 'https://moviebonus.com/wicked/'
    let redirectUrl;
    switch(env) {
      case 'dev':
        redirectUrl = 'https://localhost:3000/';
        break;
      case 'stage':
        redirectUrl = 'https://local.moviebonus.com:3000/';
        break;
      case 'prod':
        redirectUrl = 'https://moviebonus.com/wicked/';
        break;
    }
    window.nbc.identity.launch({
      registrationReferrer: 'None',
      redirectUrl: redirectUrl
    });
  }

  // Create a ref for the FAQ section
  const targetRef = useRef(null);

  // Get the current location state
  const location = useLocation();
  const state = location.state || {};

  // Scroll to FAQ section only if the state indicates the link was clicked
  useEffect(() => {
    if (state.scrollToTarget && targetRef.current) {
      targetRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [location]);

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Container>
      {isSmallScreen && userId &&
        <button sx={{ fontSize: 45, marginTop: '5px' }} className="redeem-btn-fixed button-glow"
          onClick={() => navigate('/wicked/claim-card/')}>
          {/* <LocalActivityOutlinedIcon style={{marginTop: '-3px', float: 'left', paddingRight: '10px'}}/> */}
          Redeem Now
        </button>}
      <Box
        sx={{
          display: 'flex',
          alignItems: 'flex-start',
          flexDirection: 'row',
          justifyContent: 'space-between',
          p: 0,
          m: 0,
        }}
      >
        <img
          src={wicked_logo}
          alt="Logo"
          className="logo"
          width={160}
        />
        {userId ? (
          <Grid2 className='button-group'>
            <Grid2>
              {!isSmallScreen && <button className='redeem-btn button-glow' onClick={() => navigate('/wicked/claim-card/')}>Redeem Now</button>}
              <button className='signin-btn' onClick={() => navigate('/wicked/collection')} style={{ marginLeft: 10 }}>
                View Collection
              </button>
            </Grid2>
          </Grid2>
        ) : (
          <button className='redeem-btn' onClick={login} disabled={isLoading}>
            {isLoading ? 
              <CircularProgress color="success" size={25} style={{ margin: '0 auto', display: 'block'}}/> : "Sign-In To Redeem"
            }
          </button>
        )}
      </Box>
      {/* {authenticatedUser !== null && (
        <h2 style={{
          color: '#fff',
          wordBreak: 'break-all',
          maxWidth: '100%'
        }}>
          {JSON.stringify(authenticatedUser)}
        </h2>
      )} */}
      {/* <div className="banner-container">
                <img src={poster} className='wicked-banner' alt='Wicked Banner' />
            </div> */}
      {/* <div className='gradient-section'>
            </div> */}
      <ScrollAnimation />
      <Box style={{
        // position: 'relative',
        // marginTop: -300,
        // zIndex: 9999
      }}>
        <Typography variant="h5" style={{
          maxWidth: 600,
          margin: '0 auto',
          display: 'block',
          marginBottom: 20
        }}>
          Magic awaits - start collecting now and be the first to unlock surprises in an upcoming mobile game.
        </Typography>
        {/* <h2 style={{
          color: '#fff',
          fontWeight: 300,
          textAlign: 'center',
          width: '54%',
          display: 'block',
          margin: '0px auto 40px',
          textTransform: 'uppercase',
          lineHeight: '37px',
          // letterSpacing: '2px',
          fontSize: '26px'
        }}
          sx={{ fontSize: '13px' }}>
          Magic awaits - start collecting now and be the first to unlock surprises in an upcoming mobile game!
        </h2> */}
        {userId ? (
          <button className='redeem-btn button-glow' onClick={() => navigate('/wicked/claim-card/')}
            style={{ display: 'block', margin: '0 auto' }}>
            Redeem Now
          </button>
        ) : (
          <button className='redeem-btn' onClick={login} disabled={isLoading}
            style={{ display: 'block', margin: '0 auto' }}>
            {isLoading ? <CircularProgress color="success" size={25} /> : "Sign-In To Redeem"}
          </button>
        )}
        {/* <button className='learn-btn'>Learn more</button> */}
      </Box>
      <FAQ ref={targetRef} />
      <Footer />
    </Container>
  );
};

export default HomePage;

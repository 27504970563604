import React, { useState } from "react";
import '../pages/Home.css';
import { ReactComponent as AddIcon } from '../assets/add_icon.svg';
import RemoveOutlinedIcon from '@mui/icons-material/RemoveOutlined';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import { Box, Container, Grid2 } from '@mui/material';

interface QuestionProps {
  question: string;
  answer: string;
}

const QuestionAnswer: React.FC<QuestionProps> = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false);

  // Toggle the visibility of the answer
  const toggleAnswer = () => {
    setIsOpen(!isOpen);
  };

  return (
    <Box className="questions-container">
      <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Container>
          <p style={{
            color: '#fff',
            textAlign: 'left',
            fontWeight: 600,
            // fontFamily: 'Century Gothic Regular',
            alignSelf: 'flex-start',
            wordWrap: "break-word",
            width: '100%',
            marginTop: 0,
            // border: '1px solid #ddd'

          }}>
            {question}
          </p>
          {/* Conditionally render the answer */}
          {isOpen && (
            <Grid2 justifyContent={'center'}
              style={{
                // marginTop: '30px',
                // border: '1px solid #ccc',
                borderRadius: '5px',
                color: '#14a905',
                textAlign: 'left',
                width: '100%',
              }}>
              {answer}
            </Grid2>
          )}
        </Container>
        <button onClick={toggleAnswer} style={{ backgroundColor: 'black', height: 36, width: 36, marginTop: 0 }}>
          {isOpen
            ? <RemoveOutlinedIcon style={{ fontSize: '24px', position: 'relative', right: '9px', top: '-4px' }}/>
            : <AddOutlinedIcon style={{ fontSize: '24px', position: 'relative', right: '9px', top: '-4px' }}/>}
        </button>
      </Box>
    </Box>
  );
};

export default QuestionAnswer;
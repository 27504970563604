import React, { createContext, useState, useContext } from 'react';

interface AppContextType {
  userId: string;
  setUserId: React.Dispatch<React.SetStateAction<string>>;
  isAuthenticated: boolean;
  setIsAuthenticated: React.Dispatch<React.SetStateAction<boolean>>;
  pasteCodeResult: string;
  setPasteCodeResult: React.Dispatch<React.SetStateAction<string>>;
  reward: string;
  setReward: React.Dispatch<React.SetStateAction<string>>;
  collectionIds: string[];
  addCollectionId: (collectionId: string) => void;
  removeCollectionId: (index: number) => void;
}

const AppContext = createContext<AppContextType | undefined>(undefined);

export const AppProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [userId, setUserId] = useState<string>('');
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [pasteCodeResult, setPasteCodeResult] = useState<string>('');
  const [reward, setReward] = useState<string>('');
  const [collectionIds, setCollectionIds] = useState<string[]>([]);

  const addCollectionId = (collectionId: string) => {
    setCollectionIds((prevCollectionIds) => [...prevCollectionIds, collectionId]);
  };

  const removeCollectionId = (index: number) => {
    setCollectionIds((prevCollectionIds) => prevCollectionIds.filter((_, i) => i != index));
  };

  return (
    <AppContext.Provider value={{ userId, setUserId, isAuthenticated, setIsAuthenticated, pasteCodeResult, setPasteCodeResult, reward, setReward, collectionIds, addCollectionId, removeCollectionId }}>
      {children}
    </AppContext.Provider>
  );
};

export const useAppContext = () => {
  const context = useContext(AppContext);
  if (!context) {
    throw new Error('useAppContext must be used within AppProvider');
  }
  return context;
}